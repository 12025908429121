import Header from './Header';
import styles from './Results.module.scss';

const Results = () => {
  return (
    <div className={styles.results}>
      <Header />
      <div className={styles.list}>
        <div className={styles.contained}>
          <div className={styles.settings}>
            <div className={styles.country}>
              <p>From Davao City, Philippines (en)</p>
            </div>
          </div>

          <div className={styles.include}>
            <p>
              Including results for
              <span>
                Why does Robert Espina strive to be a great team player?
              </span>
            </p>
          </div>

          <div className={styles.recent_image}>
            <p>Recent image</p>
            <img src={'/assets/me.jpg'} alt="me" />
          </div>

          <div className={styles.items}>
            <ul>
              <li>
                <div className={styles.link}>
                  <p>
                    https://getchamu.com/note/A003942?u=ea0DzNjKajzcClYhHabc
                  </p>
                </div>
                <div className={styles.title}>
                  <p>
                    <a
                      href="https://getchamu.com/note/A003942?u=ea0DzNjKajzcClYhHabc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Robert Espina Great Team Player
                    </a>
                  </p>
                </div>
                <div className={styles.message}>
                  <p>
                    Led a team of 4 with diverse opinions in a hackathon and we
                    took the trophy home. I firmly believe that the primary key
                    to building a long-running company is having an A-team and
                    amazing team players.
                  </p>
                </div>
              </li>

              <li>
                <div className={styles.link}>
                  <p>https://www.linkedin.com/in/robert-espina-09192a174/</p>
                </div>
                <div className={styles.title}>
                  <p>
                    <a
                      href="https://www.linkedin.com/in/robert-espina-09192a174/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      He doesn't let past victories slow him down
                    </a>
                  </p>
                </div>
                <div className={styles.message}>
                  <p>
                    Every day, I watch how technology evolves and strive to keep
                    an open mind. Learning never stops. Always have the
                    beginner's mindset.
                  </p>
                </div>
              </li>

              <li>
                <div className={styles.link}>
                  <p>https://murderwithmyhusband.world.co/</p>
                </div>
                <div className={styles.title}>
                  <p>
                    <a
                      href="https://murderwithmyhusband.world.co/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Problem solving doesn't intimidate him
                    </a>
                  </p>
                </div>
                <div className={styles.message}>
                  <p>
                    To embrace failure and accept you have to keep iterating,
                    that's how great software is built. With the right
                    environment, tools, and naivety, I believe these are great
                    values to build an A-team. Please, do check out the link and
                    take note that the 'Boards' , 'Theatre Media in 3D', and
                    'Artist Membership' features were my contributions.
                  </p>
                </div>
              </li>

              <li>
                <div className={styles.link}>
                  <p>https://www.linkedin.com/in/robert-espina-09192a174/</p>
                </div>
                <div className={styles.title}>
                  <p>
                    <a
                      href="https://www.linkedin.com/in/robert-espina-09192a174/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ambitious and self-driven
                    </a>
                  </p>
                </div>
                <div className={styles.message}>
                  <p>
                    I created <a href="https://getchamu.com/">Chamu</a> in hopes
                    of not only enhancing my technical skills but also to know
                    what it's like to be in the arena. Founding and building a
                    product that people would want is hard; one has to be a
                    jack-of-all-trades.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className={styles.see_more}>
            <a
              href="https://www.linkedin.com/in/robert-espina-09192a174/"
              target="_blank"
              rel="noreferrer"
            >
              <p>Show more results</p>
            </a>
          </div>

          <div className={styles.blog}>
            <p>
              Blog soon? Feel free to reach out at{' '}
              <a
                href="/#"
                target="_blank"
                onClick={(e) => {
                  if (e) {
                    e?.preventDefault();
                  }
                }}
              >
                robert.espina9627@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
