import styles from './Home.module.scss';
import SearchIcon from '../../icons/SearchIcon';
import { officialLogo } from '../../constants/icons';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const onKeydown = (evt) => {
      if (evt.keyCode === 13) {
        // navigate
        navigate('/results');
      }
    };

    document.addEventListener('keydown', onKeydown, false);

    return () => {
      document.removeEventListener('keydown', onKeydown, false);
    };
  }, [navigate]);

  return (
    <div className={styles.home}>
      <div className={styles.contained}>
        <div className={styles.logo}>
          <img src={officialLogo} alt="DuckDuckGo logo" />
        </div>

        <div className={styles.input}>
          <input defaultValue={'Search for Robert Espina'} readOnly />

          <div className={styles.tip}>
            <p>Click me!</p>
          </div>
          <Link to={'/results'}>
            <button className={styles.search}>
              <SearchIcon height={20} width={20} />
            </button>
          </Link>
        </div>
        <div className={styles.disclaimer}>
          <p>
            Disclaimer: Please note that this isn't for commercial use, not any
            way intended to hurt the{' '}
            <a target="_blank" rel="noreferrer" href="https://duckduckgo.com/">
              {' '}
              DuckDuckGo.com
            </a>{' '}
            brand. <span>Used only as my personal site's theme</span>. All
            assets and credits go to DuckDuckGo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
