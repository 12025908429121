import { useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import Results from './pages/results';
import styles from './App.module.scss';
import './App.css';
import useWindowResize from './hooks/useWindowResize';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/results',
    element: <Results />,
  },
  {
    path: '/*',
    element: <Home />,
  },
]);

const App = () => {
  const containerRef = useRef();

  useEffect(() => {
    const dom = document.getElementById('loadingParent');
    if (dom) {
      dom.remove();
    }
  }, []);

  const { availableHeight } = useWindowResize();

  useEffect(() => {
    const dom = containerRef?.current;

    if (dom) {
      dom.style.height = `${availableHeight}px`;
    }
  }, [availableHeight]);

  return (
    <div ref={containerRef} className={styles.container}>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
