import SearchIcon from '../../icons/SearchIcon';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className={styles.header}>
      <Link to={'/'}>
        <div className={styles.logo}>
          <img src={'assets/192.png'} alt="DuckDuckGo Logo" />
        </div>
      </Link>
      <div className={styles.input}>
        <div className={styles.input_text}>
          <p>
            Robert Espina as Software Engineer
          </p>
          <button className={styles.search}>
            <SearchIcon height={20} width={20} />{' '}
          </button>
        </div>
        <div className={styles.bar}>
          <div className={styles.all}>
            <SearchIcon height={20} width={20} />
            <p>All</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
